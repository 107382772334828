import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { UserService } from 'src/app/core/user.service';
import { OrganizationService } from 'src/app/core/organization.service';
import { OrganizationInfo } from 'src/models';
import { TermsGeneric } from 'src/helpers';
import { PhotoFileComponent } from 'src/app/shared/photo-file/photo-file.component';
import { LoadingIndicatorComponent } from 'src/app/shared/loading-indicator/loading-indicator.component';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { SidenavDrawerService } from 'src/app/core/sidenav-drawer.service';

/**
 * Header for dashboard menu drawer.
 */
@Component({
  selector: 'app-header-menu-drawer',
  standalone: true,
  imports: [
    CommonModule,
    LoadingIndicatorComponent,
    MatButtonModule,
    PhotoFileComponent,
    MatIconModule,
  ],
  templateUrl: './header-menu-drawer.component.html',
  styleUrls: ['./header-menu-drawer.component.scss'],
})
export class HeaderMenuDrawerComponent implements OnInit {
  /** Information about organization. */
  organizationInfo!: OrganizationInfo;

  /** Show error if fail get organization info.*/
  failedGetOrganization = false;

  /** Organization information loading inline. */
  isLoading = true;

  /** Terms generic. */
  termsGeneric = TermsGeneric;

  constructor(
    private userService: UserService,
    private organizationService: OrganizationService,
    public sidenavDrawerService: SidenavDrawerService,
  ) {}

  /**
   * Opens side nav on the dashboard.
   */
  toggleMenu(): void {
    this.sidenavDrawerService.closeSidenav();
  }

  /** Get signed in user and information about organization. */
  ngOnInit(): void {
    this.getOrganizationInfo(this.userService.user.organization);
  }

  /**
   * Get information about organization.
   * @param organizationId String of user organization.
   */
  private getOrganizationInfo(organizationId: string): void {
    this.isLoading = true;
    this.organizationService
      .getOrganizationInfo(organizationId)
      .pipe(first())
      .subscribe({
        next: (organization) => {
          this.isLoading = false;
          this.failedGetOrganization = false;
          this.organizationInfo = organization;
        },
        error: () => {
          this.isLoading = false;
          this.failedGetOrganization = true;
        },
      });
  }
}
