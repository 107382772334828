<button
  [matMenuTriggerFor]="isDashboardListOptions ? menuList : menuDashboard"
  [disabled]="isGenerateNewDashboard"
  (click)="openOptionsMenu($event)"
  mat-icon-button
  type="button"
  [attr.data-testid]="'options-dashboard-' + dashboardRole"
  [id]="'options-dashboard-' + dashboardRole"
>
  <i
    class="fa-light text-lg"
    [ngClass]="{
      'fa-plus text-base text-white': isDashboardListOptions,
      'fa-ellipsis-h text-gray-400': !isDashboardListOptions
    }"
  ></i>
</button>

<mat-menu #menuList="matMenu">
  <button
    mat-menu-item
    type="button"
    data-testid="generate-new-dashboard"
    id="generate-new-dashboard"
    (click)="generateNewDashboard(enumOrientationDashboard.Dashboard)"
  >
    <i class="fa-light fa-plus"></i>
    <span class="p-4">{{ termsGeneric.Board.Title.Single }}</span>
  </button>
  <button
    mat-menu-item
    type="button"
    data-testid="generate-new-kanban"
    id="generate-new-kanban"
    (click)="generateNewDashboard(enumOrientationDashboard.Kanban)"
  >
    <i class="fa-light fa-plus"></i>
    <span class="p-4">Kanban</span>
  </button>
  @if (dashboardRole === enumRoleDashboardMenu.Personal) {
    <button
      mat-menu-item
      type="button"
      data-testid="generate-new-dashboard-folder"
      id="generate-new-dashboard-folder"
      (click)="eventAddNewFolderDashboard.emit(true)"
    >
      <i class="fa-light fa-plus"></i>
      <span class="p-4">{{ termsGeneric.Board.Title.Single }} Folder</span>
    </button>
  }
</mat-menu>

<mat-menu #menuDashboard="matMenu">
  @if (dashboardRole !== 'bookmarked') {
    <button
      id="bookmark-dashboard"
      data-testid="bookmark-dashboard"
      mat-menu-item
      type="button"
      [disabled]="
        dashboardData.isBookmarked || (isFolder && folderData.isBookmarked)
      "
      (click)="addOrDeleteDashboardOrFolderBookmarked(true)"
    >
      <i class="fa-light fa-bookmark"></i>
      <span class="p-4">Bookmark</span>
    </button>
  }
  @if (!isFolder) {
    <button
      id="default-dashboard"
      data-testid="default-dashboard"
      mat-menu-item
      type="button"
      [disabled]="isDefaultDashboard"
      (click)="eventSetDefaultDashboard.emit(dashboardData)"
    >
      <i
        class="fa-light fa-house-blank"
        [ngClass]="{
          'text-accent-500': user.defaultDashboardId === dashboardData.rithmId
        }"
      ></i>
      <span class="p-4">Home {{ termsGeneric.Board.Title.Single }}</span>
    </button>
  }
  <!-- Button open dialog manage members. -->
  @if (
    dashboardRole === enumRoleDashboardMenu.Personal &&
    ((folderData && folderData.isEditable) ||
      (dashboardData && dashboardData.isEditable)) &&
    isArchitect
  ) {
    <button
      id="manage-dashboard"
      data-testid="manage-dashboard"
      mat-menu-item
      type="button"
      (click)="openDialogManagementMembers()"
    >
      <i class="fa-light fa-users"></i>
      <span class="p-4">Manage Members</span>
    </button>
  }
  <!-- Button remove book mark. -->
  @if (
    ((dashboardRole === enumRoleDashboardMenu.Personal &&
      dashboardData.isEditable) ||
      dashboardRole === enumRoleDashboardMenu.Company) &&
    !isFolder &&
    isArchitect
  ) {
    <button
      id="delete-dashboard"
      data-testid="delete-dashboard"
      mat-menu-item
      type="button"
      (click)="
        eventConfirmDelete.emit({ isFolder: false, element: dashboardData })
      "
    >
      <i class="fa-light fa-trash text-error-500"></i>
      <span class="p-4 text-error-500"
        >Delete {{ termsGeneric.Board.Title.Single }}</span
      >
    </button>
  }
  <!-- Button remove book mark. -->
  @if (dashboardRole === 'bookmarked') {
    <button
      id="remove-bookmark-dashboard"
      data-testid="remove-bookmark-dashboard"
      mat-menu-item
      type="button"
      (click)="addOrDeleteDashboardOrFolderBookmarked(false)"
    >
      <i class="fa-light fa-bookmark"></i>
      <span class="p-4">Remove bookmark</span>
    </button>
  }
  <!-- Button Edit. -->
  @if (
    dashboardRole === enumRoleDashboardMenu.Personal && isFolder && isArchitect
  ) {
    <button
      id="edit-dashboard-folder"
      data-testid="edit-dashboard-folder"
      mat-menu-item
      type="button"
      (click)="openAdminImageModal()"
    >
      <i class="fa-light fa-pen-to-square text-lg"></i>
      <span class="p-4">Edit Details </span>
    </button>
  }
  @if (
    isFolder && dashboardRole === enumRoleDashboardMenu.Personal && isArchitect
  ) {
    <button
      id="delete-dashboard-folder"
      data-testid="delete-dashboard-folder"
      mat-menu-item
      type="button"
      (click)="eventConfirmDelete.emit({ isFolder: true, element: folderData })"
    >
      <i class="fa-light fa-trash text-error-500"></i>
      <span class="p-4 text-error-500">Delete </span>
    </button>
  }
</mat-menu>
