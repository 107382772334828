import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { fromEvent, Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { AccountSettingsService } from 'src/app/core/account-settings.service';
import { AlertService } from 'src/app/core/alert.service';
import { BrowserNotificationService } from 'src/app/core/browser-notification.service';
import { PopupService } from 'src/app/core/popup.service';
import { SidenavDrawerService } from 'src/app/core/sidenav-drawer.service';
import { SignalRService } from 'src/app/core/signalR-service';
import { UserService } from 'src/app/core/user.service';
import { environment } from 'src/environments/environment';
import { TermsGeneric } from 'src/helpers';
import {
  AlertServiceType,
  AlertType,
  BrowserNotification,
  User,
} from 'src/models';
import { NotificationMenuContainerComponent } from '../notification-menu-container/notification-menu-container.component';
import { NotificationPushMenuContainerComponent } from '../notification-push-menu-container/notification-push-menu-container.component';
import { UserAvatarComponent } from 'src/app/shared/user-avatar/user-avatar.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { RouterModule } from '@angular/router';
import { RightClickMenuDirective } from 'src/helpers/directives/right-click-menu/right-click-menu.directive';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';

/**
 * Component for the top site navigation.
 */
@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatMenuModule,
    NotificationMenuContainerComponent,
    NotificationPushMenuContainerComponent,
    UserAvatarComponent,
    ClickOutsideModule,
    RouterModule,
    RightClickMenuDirective,
    MatSidenavModule,
    MatSlideToggleModule,
    MatTooltipModule,
    NgOptimizedImage,
  ],
})
export class TopNavComponent implements OnInit, OnDestroy {
  /** Trigger for user menu. */
  @ViewChild(MatMenuTrigger)
  private userMenuTrigger!: MatMenuTrigger;

  /** Observable for when the component is destroyed. */
  private destroyed$ = new Subject<void>();

  /** Whether the signed in user is an isArchitect or not. */
  isArchitect = false;

  /** User variable. */
  user!: User;

  /** Init the browser width. */
  innerWidth = 0;

  /** Use to toggle visibility of notifications. */
  notificationsVisible = false;

  /** The subject data for updated user first name and last name. */
  sub$ = new Subject();

  /** How many update has been done, this is to avoid an infinite loop. */
  tryUpdateUserConfiguration = 0;

  /** Use to indicate new notification. */
  newNotification = false;

  //** Enable Push Notification component visible.*/
  pushNotificationsVisible = false;

  /** Generic terms. */
  termsGeneric = TermsGeneric;

  constructor(
    public sidenavDrawerService: SidenavDrawerService,
    private userService: UserService,
    private accountSettingsService: AccountSettingsService,
    private signalRService: SignalRService,
    private alertService: AlertService,
    private popupService: PopupService,
    private browserNotification: BrowserNotificationService,
    private dialog: MatDialog,
  ) {
    this.onResize();
  }

  /** Subscribe to signalR notification event. */
  private subscribeNewNotification(): void {
    this.signalRService.receivedAlert$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data) => {
        if (data && data.type === AlertType.Browser) {
          const {
            stationRithmId,
            containerRithmId,
            stationName,
            containerName,
            note,
          } = data;
          const notificationMessage =
            data.service === AlertServiceType.Station
              ? `${stationName}\n${containerName}\n${note}`
              : note;
          const notificationUrl = `
          ${
            environment.baseAppUrl
          }/${TermsGeneric.Container.Single.toLowerCase()}/${containerRithmId}?containerId=${containerRithmId}&stationId=${stationRithmId}`;

          const browserAlert: BrowserNotification = {
            title: TermsGeneric.NameApp,
            gotoURL: notificationUrl,
            bodyContent: notificationMessage,
            imgURL: `${environment.baseAppUrl}/assets/favicons/default_UAT/favicon-196x196.png`,
          };
          this.browserNotification.showNotification(browserAlert);
        }

        if (
          data.type === AlertType.Silent ||
          (data.type === AlertType.Push && !data.hasRead)
        ) {
          this.newNotification = true;
        }
      });
  }

  /**
   * Sets updated user first name and last name in account settings component.
   *
   */
  ngOnInit(): void {
    // Setup...
    this.pushNotificationsVisible = true;
    this.user = this.userService.user as User;
    this.isArchitect = this.userService.isAdmin;
    this.accountSettingsService.currentUser$
      .pipe(takeUntil(this.sub$))
      .subscribe((user) => {
        if (
          user &&
          user.firstName !== null &&
          user.lastName !== null &&
          user.profileImageRithmId !== null
        ) {
          this.user.firstName = user?.firstName;
          this.user.lastName = user?.lastName;
          this.user.profileImageRithmId = user?.profileImageRithmId;
        }
      });

    this.signalRService.connect();
    this.subscribeNewNotification();
    this.getAlerts();
    this.subscribeOnSignOut();
  }

  /**
   * Toggle opening and closing of the mobile navigation.
   */
  toggle(): void {
    this.sidenavDrawerService.toggleSidenav();
  }

  /**
   * Close sidenavDrawer menu when only is mobile and is open.
   */
  closeMobileSidenav(): void {
    this.sidenavDrawerService.closeSidenav();
  }

  /**
   * Check if screen size changes to hide mobile nav.
   * Close user menu if screen size changes.
   */
  onResize(): void {
    fromEvent(window, 'resize')
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.innerWidth = window.innerWidth;

        if (this.innerWidth >= 768) {
          this.closeMobileSidenav();
        }
        if (this.innerWidth <= 768) {
          this.userMenuTrigger.closeMenu();
        }
      });
  }

  /**
   * Toggle opening and closing of notifications.
   */
  toggleNotifications(): void {
    this.notificationsVisible = !this.notificationsVisible;
    this.newNotification = false;
  }

  /**
   * Check if notifications are open before closing them.
   */
  clickedOutside(): void {
    if (this.notificationsVisible) {
      this.notificationsVisible = false;
      this.newNotification = false;
    }
  }

  /**
   * Signs the user out of the app.
   */
  signOut(): void {
    this.sidenavDrawerService.isSideNavOpen &&
      this.sidenavDrawerService.closeSidenav();
    if (this.dialog.openDialogs.length) {
      this.dialog.closeAll();
    }
    this.userService.onBeforeSignOut$.next();
  }

  /**
   * Update profile Image.
   */
  updateProfileImage(): void {
    this.tryUpdateUserConfiguration++;
    if (this.tryUpdateUserConfiguration < 3) {
      this.userService.updateUserData();
    }
  }

  /**
   * Gets a list of alerts.
   */
  private getAlerts(): void {
    const includeRead = false;
    this.alertService
      .getAlerts(includeRead)
      .pipe(first())
      .subscribe({
        next: (data) => {
          if (data && data.length > 0 && data.some((alert) => !alert.hasRead)) {
            this.newNotification = true;
          }
        },
        error: () => {
          this.popupService.notify(
            "Something went wrong on our end and we're looking into it. Please try again in a little while.",
            true,
          );
        },
      });
  }

  /**
   * Subscribe to signs the user out of the app.
   */
  private subscribeOnSignOut(): void {
    this.userService.onSignOut$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: () => {
        this.userService.signOut();
      },
    });
  }

  /**
   * Completes all subscriptions.
   */
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.signalRService.onDestroy();
  }
}
