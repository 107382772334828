import { Component, input, OnInit, ViewChild } from '@angular/core';
import { FolderDashboardData, RoleDashboardMenu, User } from 'src/models';
import { PopupService } from 'src/app/core/popup.service';
import { ListBoardMenuDrawerComponent } from 'src/app/navigation/menu/list-board-menu-drawer/list-board-menu-drawer.component';
import { HeaderMenuDrawerComponent } from 'src/app/navigation/menu/header-menu-drawer/header-menu-drawer.component';
import { TermsGeneric } from 'src/helpers';
import { first } from 'rxjs';
import { SplitService } from 'src/app/core/split.service';
import { UserService } from 'src/app/core/user.service';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { TabsNavigationMenuComponent } from 'src/app/navigation/menu/tabs-navigation-menu/tabs-navigation-menu.component';
import { MatDividerModule } from '@angular/material/divider';
import { UserAvatarComponent } from 'src/app/shared/user-avatar/user-avatar.component';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { SidenavDrawerService } from 'src/app/core/sidenav-drawer.service';

/**
 * Main menu component for dashboard menu drawer.
 */
@Component({
  selector: 'app-menu-drawer',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    FormsModule,
    MatButtonModule,
    MatDividerModule,
    MatMenuModule,
    MatMenuTrigger,
    RouterModule,
    HeaderMenuDrawerComponent,
    ListBoardMenuDrawerComponent,
    TabsNavigationMenuComponent,
    UserAvatarComponent,
  ],
  templateUrl: './menu-drawer.component.html',
  styleUrls: ['./menu-drawer.component.scss'],
})
export class MenuDrawerComponent implements OnInit {
  @ViewChild('bookmarkedList', { static: true })
  bookmarkedListComponent!: ListBoardMenuDrawerComponent;

  @ViewChild('companyList', { static: true })
  companyListComponent!: ListBoardMenuDrawerComponent;

  @ViewChild('personalList', { static: true })
  personalListComponent!: ListBoardMenuDrawerComponent;

  /** True if the user is architect else False. */
  isArchitect = input.required();

  /** Type role for menu in dashboard. */
  dashboardRole = RoleDashboardMenu;

  /** Edit mode menu. */
  editMode = false;

  /** Is searching. */
  isSearching = false;

  /** Param for search. */
  search = '';

  /** Init a timeout variable to be used in method get search results . */
  timeout = setTimeout(() => '', 1000);

  /** Terms generic. */
  termsGeneric = TermsGeneric;

  /** Manage members update treatment. */
  flagManagementMembersUpdate = false;

  /** The information of the current logged in user. */
  currentUser!: User;

  constructor(
    private popupService: PopupService,
    private userService: UserService,
    private splitService: SplitService,
    public sidenavDrawerService: SidenavDrawerService,
  ) {}

  /**
   * Life cycle angular.
   */
  ngOnInit(): void {
    this.currentUser = this.userService.user;
    this.getTreatment();
  }

  /**
   * Get feature flags.
   */
  private getTreatment(): void {
    const orgRithmId = this.currentUser.organization;
    this.splitService.initSdk(orgRithmId);
    this.splitService.sdkReady$.pipe(first()).subscribe({
      next: () => {
        this.flagManagementMembersUpdate =
          this.splitService.getManageMembersUpdate() === 'on';
      },
    });
  }

  /**
   * Initiate a confirmation popup for cancel changes in editMode.
   */
  async confirmCancelEditMode(): Promise<void> {
    if (
      this.checkIfThereAreChanges(this.bookmarkedListComponent) ||
      this.checkIfThereAreChanges(this.companyListComponent) ||
      this.checkIfThereAreChanges(this.personalListComponent)
    ) {
      const response = await this.popupService.confirm({
        title: 'Are you sure to cancel the changes?',
        message: 'This cannot be undone!',
        okButtonText: 'Yes',
        cancelButtonText: 'No',
        important: true,
      });
      if (response) {
        this.editMode = false;
        this.restoreCopyData();
      }
    } else {
      this.editMode = false;
    }
  }

  /**
   * Call method in list-menu-component for restore menu data.
   */
  restoreCopyData(): void {
    this.checkIfThereAreChanges(this.bookmarkedListComponent) &&
      this.bookmarkedListComponent.restoreMenuData();
    this.checkIfThereAreChanges(this.companyListComponent) &&
      this.companyListComponent.restoreMenuData();
    this.checkIfThereAreChanges(this.personalListComponent) &&
      this.personalListComponent.restoreMenuData();

    this.bookmarkedListComponent.setFolderExpanded(
      this.bookmarkedListComponent.folderDashboardData,
    );
    this.personalListComponent.setFolderExpanded(
      this.personalListComponent.folderDashboardData,
    );
  }

  /**
   * Check if there are changes in list-menu-component.
   * @param listMenuComponent Instance of list-menu-component.
   * @returns If there are changes.
   */
  checkIfThereAreChanges(
    listMenuComponent: ListBoardMenuDrawerComponent,
  ): boolean {
    return (
      JSON.stringify(listMenuComponent.dashboardsList) !==
        JSON.stringify(listMenuComponent.copyDashboardsList) ||
      JSON.stringify(listMenuComponent.folderDashboardData) !==
        JSON.stringify(listMenuComponent.copyFolderDashboardData)
    );
  }

  /**
   * Save changes in edit mode.
   */
  saveEditMode(): void {
    this.editMode = false;
    this.checkIfThereAreChanges(this.bookmarkedListComponent) &&
      this.bookmarkedListComponent.updateOrderListDashboard();
    this.checkIfThereAreChanges(this.companyListComponent) &&
      this.companyListComponent.updateOrderListDashboard();
    this.checkIfThereAreChanges(this.personalListComponent) &&
      this.personalListComponent.updateOrderListDashboard();
  }

  /**
   * Save changes in edit mode.
   */
  searchMenu(): void {
    this.isSearching = true;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      const search = this.search.trim().toLocaleLowerCase();
      //org
      this.companyListComponent.search = search;
      this.companyListComponent.dashboardsList =
        this.companyListComponent.copyDashboardsList.filter((folder) =>
          folder.name.toLowerCase().includes(search),
        );

      //Personal Dashboard Filter
      this.personalListComponent.search = search;
      this.personalListComponent.dashboardsList =
        this.personalListComponent.copyDashboardsList.filter((folder) =>
          folder.name.toLowerCase().includes(search),
        );

      // Personal folder filter
      this.personalListComponent.search = search;
      this.personalListComponent.folderDashboardData = this.folderFilter(
        search,
        [...this.personalListComponent.copyFolderDashboardData],
      );
      this.personalListComponent.setFolderExpanded(
        this.personalListComponent.folderDashboardData,
        !!search,
      );

      //Book Marked dashboard Filter
      this.bookmarkedListComponent.search = search;
      this.bookmarkedListComponent.dashboardsList =
        this.bookmarkedListComponent.copyDashboardsList.filter((folder) =>
          folder.name.toLowerCase().includes(search),
        );

      //Folders Book marked Folder Filter
      this.bookmarkedListComponent.search = search;
      this.bookmarkedListComponent.folderDashboardData = this.folderFilter(
        search,
        [...this.bookmarkedListComponent.copyFolderDashboardData],
      );
      this.bookmarkedListComponent.setFolderExpanded(
        this.personalListComponent.folderDashboardData,
        !!search,
      );
      this.isSearching = false;
    }, 750);
  }

  /**
   *Folder Filter.
   * @param search Term to search.
   * @param folders Folder to filter.
   * @returns Filtered Data.
   */
  private folderFilter(
    search: string,
    folders: FolderDashboardData[],
  ): FolderDashboardData[] {
    let foldersFiltered: FolderDashboardData[] = [];
    //Dashboard Folders with at least one coincidence.
    const dashboardsCoincidence = [
      ...folders.filter((folder) => {
        return folder.dashboards.some((dashboard) => {
          return dashboard.name.toLowerCase().trim().includes(search);
        });
      }),
    ];

    //Folder matching
    const folderMatching = [
      ...folders.filter((folder) => {
        return folder.name.toLowerCase().trim().includes(search);
      }),
    ];

    //Merge two arrays
    foldersFiltered = [...folderMatching, ...dashboardsCoincidence];
    //Delete duplicates
    foldersFiltered = [...new Set(foldersFiltered)];

    //Filter dashboards without folder parent matching search term

    foldersFiltered.forEach((folder) => {
      if (!folder.name.toLowerCase().trim().includes(search)) {
        folder.dashboards = folder.dashboards.filter((dashboard) =>
          dashboard.name.toLowerCase().trim().includes(search),
        );
      }
    });
    return search ? foldersFiltered : folders;
  }

  /**
   * Enable editMode.
   */
  enableEditMode(): void {
    this.editMode = true;
    if (this.search) {
      this.search = '';
      this.restoreCopyData();
    }
  }

  /** Signs the user out of the app. */
  signOut(): void {
    this.sidenavDrawerService.closeSidenav();
    this.userService.onBeforeSignOut$.next();
  }
}
